<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="flex-grow">
                <div class="vx-col md:w-1/4 sm:w-auto mr-4">
                    <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                              :options="organizationBranchOptions">
                              <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                </div>
            </div>
            <div class="flex flex-wrap items-center mt-5">
                <div class="flex-grow">
                    <vs-button
                        @click="addDailyWork"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-settings">
                        Thêm công việc hàng ngày
                    </vs-button>

                </div>
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="dailyWorkInfo.dailyWorkSetting"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="20"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import CellRendererActions from "./cell-renderer/OrganizationBranchCellRendererActions.vue"
import utils from "../../../components/utils";

export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererActions,
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    width: 100
                },
                {
                    headerName: 'Tên ca',
                    field: 'name',
                    width: 150
                },
                {
                    headerName: 'Ngày làm việc',
                    field: 'workDayOfWeek',
                    width: 150,
                },
                {
                    headerName: 'Bắt đầu',
                    field: 'startHour',
                    width: 150,
                },
                {
                    headerName: 'Kết thúc',
                    field: 'endHour',
                    width: 150,
                },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    width: 100,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            dailyWorkInfo: {
                name: '',
                dailyWorkSetting: []
            },
            timeSpans: [],
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null,
            workDayOfWeekOptions: [
                {value: 2, label: 'Thứ 2'},
                {value: 3, label: 'Thứ 3'},
                {value: 4, label: 'Thứ 4'},
                {value: 5, label: 'Thứ 5'},
                {value: 6, label: 'Thứ 6'},
                {value: 7, label: 'Thứ 7'},
                {value: 1, label: 'Chủ nhật'}
            ]
        }
    },
    created() {
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        this.initScreen()
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateOrganizationWorkShift'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_ORGANIZATION_WORK_SHIFT', false);
            }
        }
    },
    computed: {
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value
                this.loadWorkShift()
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || 'Chưa xác định';
        },
        addDailyWork() {
            this.$router.push(`/user/add-daily-work?branch_id=${this.selectedOrganizationBranchValue}`);
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]')
            if (branchList.length == 0) {
                return this.$vs.notify({
                    text: 'Hiện tại không tồn tại chi nhánh nào',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            })
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];
        },
        loadWorkShift() {
            if (!this.selectedOrganizationBranchValue) return;

            this.$vs.loading();
            this.$crm.post('/daily-work/organization/find-by-organization-branch/' + this.selectedOrganizationBranchValue).then((response) => {
                let dailyWorkInfo = response.data;
                dailyWorkInfo.dailyWorkSetting.map(item => {
                    item.organizationBranchId = this.selectedOrganizationBranchValue
                    item.startHour = this.timeSpans.find(timeSpan => timeSpan.value == item.startHour).label;
                    item.endHour = this.timeSpans.find(timeSpan => timeSpan.value == item.endHour).label;
                })
                dailyWorkInfo.dailyWorkSetting.sort((item1, item2) => {
                    if (item1.groupName < item2.groupName) return -1;
                    if (item1.groupName > item2.groupName) return 1;
                    return 0;
                });
                this.dailyWorkInfo = dailyWorkInfo;
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
